var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"data-app":""}},[_c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',{staticStyle:{"color":"#e33354"},attrs:{"cols":"12","sm":"6","md":"6"}},[_c('h3',[_vm._v(_vm._s(_vm.$t("MENU.ACTIVITIES")))])])],1)],1),_c('v-card-text',[_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('v-menu',{ref:"date_picker_created_at",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"clearable":"","label":_vm.$t('FORMS.date_from'),"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.options.from),callback:function ($$v) {_vm.$set(_vm.options, "from", $$v)},expression:"options.from"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.date_picker_created_at),callback:function ($$v) {_vm.date_picker_created_at=$$v},expression:"date_picker_created_at"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","locale":"hu-HU"},model:{value:(_vm.options.from),callback:function ($$v) {_vm.$set(_vm.options, "from", $$v)},expression:"options.from"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.date_picker_created_at = false}}},[_vm._v(" "+_vm._s(_vm.$t("FORMS.cancel"))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.date_picker_created_at.save(_vm.options.from)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('v-menu',{ref:"date_picker_created_to",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"clearable":"","label":_vm.$t('FORMS.date_to'),"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.options.to),callback:function ($$v) {_vm.$set(_vm.options, "to", $$v)},expression:"options.to"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.date_picker_created_to),callback:function ($$v) {_vm.date_picker_created_to=$$v},expression:"date_picker_created_to"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","locale":"hu-HU"},model:{value:(_vm.options.to),callback:function ($$v) {_vm.$set(_vm.options, "to", $$v)},expression:"options.to"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.date_picker_created_to = false}}},[_vm._v(" "+_vm._s(_vm.$t("FORMS.cancel"))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.date_picker_created_to.save(_vm.options.to)}}},[_vm._v(" OK ")])],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.activityCollection,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loadingTable,"footer-props":{
              'items-per-page-options': [10, 100, 500],
            }},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.handleClickItem},scopedSlots:_vm._u([{key:"header",fn:function(ref){
            var headers = ref.props.headers;
return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',[_c('th',[(
                        _vm.activityCollection && _vm.activityCollection.length > 0
                      )?_c('v-btn',{attrs:{"size":"x-small"},on:{"click":_vm.handleExport}},[_vm._v(" export ")]):_vm._e()],1),_c('th',[_c('v-select',{attrs:{"items":[
                        { id: null, name: 'Összes felhasználó' } ].concat( _vm.userCollection ),"item-text":"name","item-value":"id","disabled":_vm.permissionCan('self')},model:{value:(_vm.options.user_id),callback:function ($$v) {_vm.$set(_vm.options, "user_id", $$v)},expression:"options.user_id"}})],1),_c('th',[_c('v-select',{attrs:{"items":[
                        { id: null, name: _vm.$t('TABLE.all_sites') } ].concat( _vm.siteCollection ),"item-text":"name","item-value":"id"},model:{value:(_vm.options.site),callback:function ($$v) {_vm.$set(_vm.options, "site", $$v)},expression:"options.site"}})],1),_c('th',[_c('v-select',{attrs:{"items":['All' ].concat( _vm.activityModels)},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(_vm.translateModel(data.item))+" ")]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(_vm.translateModel(data.item))+" ")]}}],null,true),model:{value:(_vm.options.model_name),callback:function ($$v) {_vm.$set(_vm.options, "model_name", $$v)},expression:"options.model_name"}})],1),_c('th',[_c('v-select',{attrs:{"items":[
                        { key: null, value: 'All' } ].concat( _vm.activityActions ),"item-text":"value","item-value":"key"},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(_vm.translateEvent(data.item.value))+" ")]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(_vm.translateEvent(data.item.value))+" ")]}}],null,true),model:{value:(_vm.options.action),callback:function ($$v) {_vm.$set(_vm.options, "action", $$v)},expression:"options.action"}})],1)])])]}},{key:"item.site",fn:function(ref){
                      var item = ref.item;
return [(_vm.siteCollection)?_c('span',[_vm._v(" "+_vm._s(_vm.getSiteName(item.site_id))+" ")]):_vm._e()]}},{key:"item.loggable_type",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.translateModel(item.loggable_type))+" ")]}},{key:"item.action",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.translateEvent(item.action))+" ")]}}])})],1)],1),_c('ActivityModalForm',{attrs:{"modalData":_vm.modalData,"permissions":_vm.permissions,"activityActions":_vm.activityActions,"statuses":_vm.statuses,"endPoint":_vm.endPoint,"newsCategoryCollection":_vm.newsCategoryCollection},on:{"closeModalForm":_vm.handleCloseModalForm,"saveModalForm":_vm.handleSaveModalForm}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }