<template>
  <div data-app>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="6" md="6" style="color: #e33354">
            <h3>{{ $t("MENU.ACTIVITIES") }}</h3>
          </v-col>
          <!-- <v-spacer></v-spacer>
          <v-col
            cols="12"
            sm="2"
            md="2"
            v-if="activityCollection && activityCollection.length > 0"
            class="text-right"
          >
            <v-btn size="x-small" @click="handleExport"> export </v-btn>
          </v-col> -->
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="3" md="3">
            <v-menu
              ref="date_picker_created_at"
              v-model="date_picker_created_at"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  clearable
                  v-model="options.from"
                  :label="$t('FORMS.date_from')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="options.from"
                no-title
                scrollable
                locale="hu-HU"
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="date_picker_created_at = false"
                >
                  {{ $t("FORMS.cancel") }}
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.date_picker_created_at.save(options.from)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <v-menu
              ref="date_picker_created_to"
              v-model="date_picker_created_to"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="options.to"
                  clearable
                  :label="$t('FORMS.date_to')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="options.to"
                no-title
                scrollable
                locale="hu-HU"
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="date_picker_created_to = false"
                >
                  {{ $t("FORMS.cancel") }}
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.date_picker_created_to.save(options.to)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <!-- v-if="activityCollection && activityCollection.length > 0" -->
            <v-data-table
              :headers="headers"
              :items="activityCollection"
              :options.sync="options"
              :server-items-length="totalItems"
              :loading="loadingTable"
              class="elevation-1"
              :footer-props="{
                'items-per-page-options': [10, 100, 500],
              }"
              @click:row="handleClickItem"
            >
              <template #header="{ props: { headers } }">
                <thead class="v-data-table-header">
                  <tr>
                    <th>
                      <v-btn
                        size="x-small"
                        @click="handleExport"
                        v-if="
                          activityCollection && activityCollection.length > 0
                        "
                      >
                        export
                      </v-btn>
                    </th>
                    <th>
                      <v-select
                        v-model="options.user_id"
                        :items="[
                          { id: null, name: 'Összes felhasználó' },
                          ...userCollection,
                        ]"
                        item-text="name"
                        item-value="id"
                        :disabled="permissionCan('self')"
                      ></v-select>
                    </th>
                    <th>
                      <v-select
                        v-model="options.site"
                        :items="[
                          { id: null, name: $t('TABLE.all_sites') },
                          ...siteCollection,
                        ]"
                        item-text="name"
                        item-value="id"
                      ></v-select>
                    </th>
                    <th>
                      <v-select
                        v-model="options.model_name"
                        :items="['All', ...activityModels]"
                      >
                        <template slot="selection" slot-scope="data">
                          {{ translateModel(data.item) }}
                        </template>
                        <template slot="item" slot-scope="data">
                          {{ translateModel(data.item) }}
                        </template>
                      </v-select>
                    </th>
                    <th>
                      <v-select
                        v-model="options.action"
                        :items="[
                          { key: null, value: 'All' },
                          ...activityActions,
                        ]"
                        item-text="value"
                        item-value="key"
                      >
                        <template slot="selection" slot-scope="data">
                          {{ translateEvent(data.item.value) }}
                        </template>
                        <template slot="item" slot-scope="data">
                          {{ translateEvent(data.item.value) }}
                        </template>
                      </v-select>
                    </th>
                  </tr>
                </thead>
              </template>
              <template v-slot:item.site="{ item }">
                <span v-if="siteCollection">
                  {{ getSiteName(item.site_id) }}
                </span>
              </template>
              <template v-slot:item.loggable_type="{ item }">
                {{ translateModel(item.loggable_type) }}
              </template>
              <template v-slot:item.action="{ item }">
                {{ translateEvent(item.action) }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <ActivityModalForm
          :modalData="modalData"
          :permissions="permissions"
          :activityActions="activityActions"
          :statuses="statuses"
          :endPoint="endPoint"
          :newsCategoryCollection="newsCategoryCollection"
          @closeModalForm="handleCloseModalForm"
          @saveModalForm="handleSaveModalForm"
        ></ActivityModalForm>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

// import SiteService from "@/core/services/site.service.js";

import { listModelsMixins } from "@/view/mixins/listModelsMixins.js";

// import ActivityModalForm, { initialFormData } from "./ActivityModalForm";
import ActivityModalForm, { initialFormData } from "./ActivityModalForm";

// import DeleteModalDialog from "@/view/components/DeleteModalDialog";

import ApiService from "@/core/services/api.service";

export const PERMISSION_TO = "system.activity.";

// const INITIAL_HEADER_CATEGORIES = [
//   { id: 2, name: "Sportág", values: [] },
//   { id: 1, name: "Esemény", values: [] },
//   { id: 3, name: "Szakszemmel", values: [] },
// ];

import i18nService from "@/core/services/i18n.service.js";

export default {
  name: "Activities",
  components: { ActivityModalForm },
  mixins: [listModelsMixins],
  data() {
    return {
      cardTitle: this.$t("MENU.POSTS"),
      search: "",
      routePath: "/settings/activities/",
      selectedLocale: i18nService.userLanguages.find((item) => {
        return item.lang == i18nService.getUserActiveLanguage();
      }).lang,
      permissionTo: PERMISSION_TO,
      permissions: JSON.parse(localStorage.permissions),
      headers: [
        {
          text: this.$t("TABLE.created_"),
          value: "created_at",
          sortable: false,
          width: "200px",
        },
        {
          text: this.$t("TABLE.user"),
          value: "user_name",
          sortable: false,
        },
        {
          text: this.$t("TABLE.site"),
          value: "site",
          sortable: false,
        },
        {
          text: this.$t("TABLE.model"),
          value: "loggable_type",
          sortable: false,
        },
        {
          text: this.$t("TABLE.action"),
          value: "action",
          sortable: false,
        },
      ],

      activityCollection: [],
      formValid: false,

      modalData: {
        dialog: false,
        editedId: null,
      },
      editedItem: Object.assign({}, initialFormData()),

      dialogDelete: false,

      totalItems: 0,
      options: {
        sortBy: ["updated_at"],
        sortDesc: [true],
        itemsPerPage: 100,
        page: 1,
        from: null,
        to: null,
        model_name: "All",
        action: null,
        user_id: null,
        site: null,
        searchTerm: null,
        // my_recently_modified_items: true,
      },

      date_picker_created_from: false,
      created_at_date: null,
      time_picker_created_at: false,
      created_at_time: null,

      date_picker_created_to: false,

      to: false,
      created_at_date_to: null,
      time_picker_created_at_to: false,
      created_at_time_to: null,

      shortcut_last_updated_by_me: true,
      shortcut_draft: false,
      shortcut_awaiting_acceptance: false,
      shortcut_published: false,

      // headerCategories: [...INITIAL_HEADER_CATEGORIES],
    };
  },

  computed: {
    ...mapGetters([
      "activity",
      "userCollection",
      "getUserByID",
      "currentUser",
      "siteCollection",
    ]),
    endPoint() {
      return "activities/";
    },

    activityActions() {
      return this.activity.actions;
    },

    activityModels() {
      if (!this.activity || !this.activity.models) return [];
      return this.activity.models.filter((i) => !!i);
    },
  },

  watch: {
    dialogDelete(val) {
      val || this.handleCloseDelete();
    },
    "$route.params.id": {
      handler: function (id) {
        if (id) {
          this.editItem(id);
        }
      },
      deep: true,
      immediate: true,
    },

    options: {
      handler() {
        if (!this.loadingTable) this.getDataFromApi();
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions(["fetchNewsCategory", "fetchUser", "fetchSite"]),

    getDataFromApi() {
      this.loadingTable = true;

      ApiService.post("activities/", this.options)
        .then(({ data }) => {
          this.activityCollection = data.collection.map((item) => {
            let action = this.activityActions.find((i) => i.key == item.action);

            if (item.loggable_type) {
              let loggable_typeArray = item.loggable_type.split("\\");
              item.loggable_type =
                loggable_typeArray[loggable_typeArray.length - 1];
            }
            if (action) {
              item.action = action.value;
            }
            item.created_at = this.formatDate(item.created_at);
            return item;
          });
          this.totalItems = data.totalItems;
        })
        .catch((error) => {
          if (error.response) {
            let errors = error.response.data;
            if (errors) {
              for (let field in errors.errors) {
                this.setError(field, errors.errors[field][0]);
              }
              console.log(this.messages);
            }
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
          console.log("Error!: ", error);
          // this.modalData.loading = false;
        })
        .finally(() => {
          this.loadingTable = false;
        });
    },

    fetchModel() {
      this.fetchSite();
      this.fetchUser().then(() => {
        if (this.permissionCan("self")) {
          this.options.user_id = this.currentUser.id;
        }
        this.getDataFromApi();
      });
    },

    formatDate(date) {
      var d = new Date(date);
      return d.toLocaleString("hu-HU");
    },

    handleShowMyActivities(state) {
      let options = Object.assign({}, this.options);
      options.page = 1;
      options.user_id = null;
      options.locale = this.selectedLocale;
      options.searchTerm = this.search;

      options.itemsPerPage = 50;
      options.sortDesc = [true];
      options.sortBy = ["updated_at"];
      options.my_recently_modified_items = state;
      if (state) {
        this.search = null;
        options.searchTerm = null;
        options.user_id = this.currentUser.id;
      }

      this.options = Object.assign({}, options);
      // this.getDataFromApi();
    },

    handleShowDraft(state) {
      let options = Object.assign({}, this.options);
      options.page = 1;
      options.sortDesc = [true];
      options.sortBy = ["updated_at"];
      options.itemsPerPage = 50;
      if (state) {
        options.status = 0;
        this.shortcut_awaiting_acceptance = false;
        this.shortcut_published = false;

        this.search = null;
        options.searchTerm = null;
      } else {
        options.status = null;
      }
      // this.getDataFromApi();
      this.options = Object.assign({}, options);
    },

    handleShowDraft2(state) {
      let options = Object.assign({}, this.options);
      options.page = 1;

      options.sortDesc = [true];
      options.sortBy = ["updated_at"];
      options.itemsPerPage = 50;
      if (state) {
        options.status = 1;
        this.shortcut_draft = false;
        this.shortcut_published = false;

        this.search = null;
        options.searchTerm = null;
      } else {
        options.status = null;
      }
      // this.getDataFromApi();
      this.options = Object.assign({}, options);
    },

    handleShowDraft3(state) {
      let options = Object.assign({}, this.options);
      options.page = 1;
      options.sortDesc = [true];
      options.sortBy = ["updated_at"];
      options.itemsPerPage = 50;
      if (state) {
        options.status = 2;
        this.shortcut_draft = false;
        this.shortcut_awaiting_acceptance = false;

        this.search = null;
        options.searchTerm = null;
      } else {
        options.status = null;
      }
      this.options = Object.assign({}, options);
    },

    handleSelectHeaderCategory(category) {
      if (this.loadingTable) return null;
      this.options.sortDesc = [true];
      this.options.sortBy = ["updated_at"];
      this.options.itemsPerPage = 50;

      this.search = null;
      this.options.searchTerm = null;

      this.options.category_id = category.id;
      this.getDataFromApi();
    },

    handleResetHeaderCategoryFilter() {
      if (this.loadingTable) return null;
      this.options.category_id = null;
      this.getDataFromApi();
    },

    handleClickItem(item) {
      this.$router.push(this.routePath + item.id);
    },

    formatToCSVrow(row) {
      let formatted = [];

      formatted.push(row.created_at);
      formatted.push(row.user_name);
      formatted.push(row.loggable_type);
      formatted.push(row.loggable_id);
      formatted.push(row.action);
      formatted.push(row.properties ? JSON.stringify(row.properties) : "");

      return formatted;
    },

    exportToCsv(filename, elements) {
      let rows = [];
      let header = [
        "Idúpont",
        "Felhasználó",
        "Modul",
        "Azonosító",
        "Művelet",
        "Tulajdonságok",
      ];

      rows = [header, ...rows];
      let formatted = null;
      elements.forEach((element) => {
        formatted = this.formatToCSVrow(element);
        if (formatted) {
          rows = [...rows, formatted];
        }
      });

      var processRow = function (row) {
        var finalVal = "";
        for (var j = 0; j < row.length; j++) {
          var innerValue = row[j] === null ? "" : row[j].toString();
          if (row[j] instanceof Date) {
            innerValue = row[j].toLocaleString();
          }
          var result = innerValue.replace(/"/g, '""');
          if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
          if (j > 0) finalVal += ";";
          finalVal += result;
        }
        return finalVal + "\n";
      };

      var csvFile = "";
      for (var i = 0; i < rows.length; i++) {
        csvFile += processRow(rows[i]);
      }

      var blob = new Blob([csvFile], { type: "text/csv;charset=win1250;" });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, filename);
      } else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", filename);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },

    handleExport() {
      let now = new Date(new Date()).toLocaleString("hu-HU", {
        timeZone: "UTC",
      });

      this.exportToCsv(
        `activities_${now.replace(" ", "")}.csv`,
        this.activityCollection
      );
    },

    getSiteName(id) {
      if (id) {
        let site = this.siteCollection.find((item) => (item.id = id));

        if (site) return site.name;
      }
      return null;
    },

    translateModel(item) {
      if (!item) {
        return "";
      }
      let itemArray = item.split("\\");

      return this.$t("ACTIVITY_MODEL." + itemArray[itemArray.length - 1]);
    },

    translateEvent(item) {
      if (!item) {
        return "";
      }
      let itemArray = item.split("\\");

      return this.$t("ACTIVITY_EVENT." + itemArray[itemArray.length - 1]);
    },
  },

  mounted() {
    this.loadingTable = true;

    this.fetchModel();
  },
};
</script>

<style scoped>
.header-category:hover {
  color: #e33354;
  cursor: pointer;
}

.active {
  font-weight: 600;
  color: #e33354;
}
</style>
