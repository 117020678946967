<template>
  <v-dialog
    v-model="modalData.dialog"
    max-width="750px"
    persistent
    scrollable
    :retain-focus="false"
  >
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="headline">{{ formTitle }} </span>
            <v-btn @click="handleCloseModalForm" icon class="close-button">
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-table>
              <thead>
                <tr>
                  <th class="text-left table-key">Megnevezés</th>
                  <th class="text-left table-key">Részletek</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ $t("TABLE.created_") }}</td>
                  <td>{{ formModel.created_at }}</td>
                </tr>
                <tr>
                  <td>{{ $t("TABLE.user") }}</td>
                  <td>{{ formModel.user_name }}</td>
                </tr>
                <tr>
                  <td>{{ $t("TABLE.action") }}</td>
                  <td>
                    {{
                      formModel.action
                        ? $t("ACTIVITY_EVENT." + formModel.action)
                        : ""
                    }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $t("TABLE.id") }}</td>
                  <td>{{ formModel.loggable_id }}</td>
                </tr>
                <tr>
                  <td>{{ $t("TABLE.modul") }}</td>
                  <td>
                    {{
                      formModel.modul
                        ? $t("ACTIVITY_MODUL." + formModel.modul)
                        : $t("ACTIVITY_MODUL.System")
                    }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $t("TABLE.properties") }}</td>
                  <td>
                    <!-- {{JSON.parse(json)}} -->
                    <pre
                      v-if="
                        formModel.properties && formModel.properties.changes
                      "
                      >{{
                        JSON.parse(formModel.properties.changes.custom_fields)
                      }}</pre
                    >
                  </td>
                </tr>
                <!-- <tr>
                  <td>{{ $t("TABLE.user") }}</td>
                  <td>{{ formModel.user_name }}</td>
                </tr> -->
              </tbody>
            </v-table>
          </v-col>
        </v-row>

        <!-- <pre>{{ formModel }}</pre> -->
      </v-card-text>

      <v-card-actions class="mb-2">
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="handleCloseModalForm">
          {{ $t("FORMS.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import { formModelMixins } from "@/view/mixins/formModelMixins.js";

//import Treeselect from "@riophae/vue-treeselect";
//import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import i18nService from "@/core/services/i18n.service.js";
import ApiService from "@/core/services/api.service";

// const TRANSLATED_ATRIBUTES = [];

export const INITIAL_TRANSLATED_CUSTOM_FIELDS = [];

export const INITIAL_CUSTOM_FIELDS = {};

export const initialFormData = () => ({});

export default {
  name: "ActivitiModalForm",
  props: ["modalData", "endPoint", "activityActions"],
  mixins: [formModelMixins],
  data() {
    return {
      result: null,
      languages: i18nService.userLanguages,
      selectedLocale: i18nService.userLanguages.find((item) => {
        return item.lang == i18nService.getUserActiveLanguage();
      }),
      formModel: Object.assign({}, initialFormData()),
      formValid: false,
      initialTransaltedCustomFields: INITIAL_TRANSLATED_CUSTOM_FIELDS,

      date_picker_published_at: false,
      published_at_date: null,
      time_picker_published_at: false,
      published_at_time: null,

      messages: {},
    };
  },
  computed: {
    initialFormData() {
      return initialFormData;
    },
  },

  watch: {
    modalData: {
      deep: true,
      handler(value) {
        if (value.editedId) {
          this.loader = true;
          this.formModel = Object.assign({}, initialFormData());
          ApiService.get(this.endPoint + value.editedId)
            .then(({ data }) => {
              let action = this.activityActions.find(
                (i) => i.key == data.action
              );

              if (data.loggable_type) {
                let loggable_typeArray = data.loggable_type.split("\\");
                data.loggable_type =
                  loggable_typeArray[loggable_typeArray.length - 1];
              }
              if (action) {
                data.action = action.value;
              }
              data.created_at = this.formatDate(data.created_at);

              this.formModel = Object.assign({}, data);
            })
            .catch((error) => {
              console.log("Error!: ", error);
            })
            .finally(() => {
              this.loader = false;
            });
        }
      },
    },
  },
  methods: {
    formatDate(date) {
      var d = new Date(date);
      return d.toLocaleString("hu-HU");
    },
  },

  mounted() {},
};
</script>

<style>
.table-key {
  width: 30%;
}
</style>
